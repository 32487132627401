import React, { useEffect, useRef, useState } from "react";
import axiosInstance from "../../config/axiosConfig";
import {
    AppBar,
    Box,
    Button,
    Container,
    CssBaseline,
    FormControl,
    InputAdornment,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Stack,
    Toolbar,
} from "@mui/material";
import { useNavigate } from "react-router";
import { Grid, Card, CardContent, CardMedia, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import { getDepartments } from "../../services/departmentService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import CategoryIcon from "@mui/icons-material/Category";
import SearchIcon from "@mui/icons-material/Search";
import BusinessIcon from "@mui/icons-material/Business";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Loader from "../common/loader";
import Header from "../header/header";
import Footer from "../footer/footer";
import InteractiveButton from "./downloadButton";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

function Project() {
    const printRef = useRef();
    const [projects, setProjects] = useState([]);
    const [filteredProjects, setFilteredProjects] = useState([]);
    const navigate = useNavigate();
    const [departments, setDepartments] = useState([]);
    const [technologies, setTechnologies] = useState([]);
    const [search, setSearch] = useState("");

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

    const [categories, setCategories] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState("All");
    const [deptLink, setDeptLink] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("All");
    const [isLoading, setIsLoading] = useState(false);
    const [progressMessage, setProgressMessage] = useState("");

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [firstDropdown, setFirstDropdown] = React.useState("");
    const [secondDropdown, setSecondDropdown] = React.useState("");
    const [inputValue, setInputValue] = React.useState("");

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getProjectsByDept = (dept) => {
        if (dept === "All") return projects;
        return projects.filter((project) => project.department === dept);
    };

    const getProjectsByDeptAndCat = (dept, cat) => {
        if (dept === "All") return projects;
        else if (dept != "All" && cat === "All") return getProjectsByDept(dept);
        return projects.filter((project) => project.department === dept && project.category === cat);
    };

    const handleDownloadPdf = async () => {
        try {
            const element = printRef.current;

            // Ensure proper rendering
            const canvas = await html2canvas(element, {
                useCORS: true,
                allowTaint: true,
                backgroundColor: "#ffffff",
                scale: 1, // Improve quality
                scrollX: 0,
                scrollY: 0,
                width: element.scrollWidth,
                height: element.scrollHeight,
            });

            // Validate image data
            const imgData = canvas.toDataURL("image/png");
            if (!imgData || !imgData.startsWith("data:image/png")) {
                console.error("Invalid PNG Data:", imgData);
                throw new Error("Generated PNG data is invalid.");
            }

            // Generate PDF
            const pdf = new jsPDF("landscape", "pt", "a4");
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

            if (pdfHeight > pdf.internal.pageSize.getHeight()) {
                let position = 0;
                while (position < pdfHeight) {
                    pdf.addImage(imgData, "PNG", 0, position, pdfWidth, pdfHeight);
                    position += pdf.internal.pageSize.getHeight();
                    if (position < pdfHeight) pdf.addPage();
                }
            } else {
                pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
            }

            pdf.save("projects-table.pdf");
        } catch (error) {
            console.error("Error generating PDF:", error.message);
        }
    };

    const handleCategoryChange = (e) => {
        e.preventDefault();
        setIsLoading(true);
        setSearch("");

        let category = e.target.value;
        if (category != "All") {
            setProgressMessage("Getting projects for " + category + " category.");
            setSelectedCategory(category);
            const filtered = getProjectsByDept(selectedDepartment).filter((project) => project.category === category);
            setFilteredProjects(filtered);
        } else {
            setProgressMessage("Getting projects list");
            setSelectedCategory("All");
            setFilteredProjects(getProjectsByDept(selectedDepartment));
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    };

    const handleDeptChange = (e) => {
        e.preventDefault();
        setSelectedCategory("All");
        setSearch("");
        setIsLoading(true);
        let code = e.target.value;
        if (code !== "All") {
            const dept = departments.find((d) => d.code === code);
            setSelectedDepartment(dept.code);
            setDeptLink(dept.documentLink);
            setCategories(dept.categories || []);
            setProgressMessage("Getting projects for " + dept.code + " department.");
            const filtered = projects.filter((project) => project.department === code);
            setFilteredProjects(filtered);
        } else {
            setSelectedDepartment("All");
            setProgressMessage("Getting projects list");
            setFilteredProjects(projects);
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    };

    const handleSearch = (e) => {
        e.preventDefault();
        setIsLoading(true);
        let val = e.target.value;
        if (val !== "") setProgressMessage("Searching for " + e.target.value);
        else setProgressMessage("Getting projects list");
        setSearch(val);
        const filtered = getProjectsByDeptAndCat(selectedDepartment, selectedCategory).filter((project) =>
            project.title.toLowerCase().includes(val.toLowerCase())
        );
        setFilteredProjects(filtered);
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    };

    const renderFilters = () => (
        <Paper elevation={2} sx={{ border: "1px solid #cacaca" }}>
            <Box sx={{ padding: 2 }}>
                <Box pb={1} sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <Typography variant='h6'>Filters</Typography>
                </Box>

                <FormControl fullWidth>
                    {/* <label htmlFor='department'>Department</label> */}
                    {/* <InputLabel id='demo-simple-select-label'>Department</InputLabel> */}
                    <Select
                        name='department'
                        value={selectedDepartment}
                        // label='Department'
                        onChange={handleDeptChange}
                        input={<OutlinedInput size='small' />}
                        startAdornment={
                            <InputAdornment position='start'>
                                <BusinessIcon color='secondary' />
                            </InputAdornment>
                        }
                    >
                        <MenuItem value='All'>All</MenuItem>
                        {departments.map((dept, key) => (
                            <MenuItem key={key} value={dept.code}>
                                {dept.code}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <br />
                <br />
                <FormControl fullWidth>
                    <Select
                        placeholder='select category'
                        name='category'
                        // label='Category'
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                        input={<OutlinedInput size='small' />}
                        startAdornment={
                            <InputAdornment position='start'>
                                <CategoryIcon color='warning' />
                            </InputAdornment>
                        }
                    >
                        <MenuItem value='All'>All</MenuItem>
                        {categories.map((category, key) => (
                            <MenuItem key={key} value={category.name}>
                                {category.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        </Paper>
    );

    const renderProjectCards = () => (
        <>
            <Container maxWidth='xl'>
                <InteractiveButton filteredProjects={filteredProjects} selectedDepartment={selectedDepartment} deptLink={deptLink} />

                {filteredProjects.length > 0 && (
                    <Typography variant='h6' mb={1}>
                        Top Listed Projects
                    </Typography>
                )}
                <Grid container spacing={2} pb={2} className='projects'>
                    {filteredProjects.slice(0, isMobile ? 2 : isTablet ? 3 : isDesktop ? 4 : 6).map((project) => (
                        <Grid item xs={6} sm={4} md={3} lg={3} xl={2} key={project.id}>
                            <Card sx={{ border: "1px solid #cacaca", cursor: "pointer" }} onClick={() => navigate(`/project/${project.id}`)}>
                                <CardMedia
                                    sx={{ backgroundSize: "cover", backgroundPosition: "center" }}
                                    component='img'
                                    height='140'
                                    image={require(`../../assets/images/${project.department}.png`)}
                                    alt={project.title}
                                />
                                <CardContent>
                                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", height: "auto", alignItems: "center" }}>
                                        <Typography
                                            sx={{
                                                fontWeight: "600",
                                                lineHeight: "1.3",
                                                color: "#333",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                            }}
                                            variant='body2'
                                            className='project-title'
                                        >
                                            {project.title}
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}

                    {filteredProjects?.length === 0 && (
                        <Box p={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                            <Typography>
                                No projects found for {selectedDepartment !== "All" ? selectedDepartment : ""}{" "}
                                {selectedCategory !== "All" ? ": " + selectedCategory : ""} {search !== "" ? ": " + search : ""}
                            </Typography>
                        </Box>
                    )}
                </Grid>

                {filteredProjects?.length > 0 && (
                    <Paper elevation={2} sx={{ width: "99%", margin: "8px auto", overflow: "hidden" }}>
                        <TableContainer>
                            <Table stickyHeader aria-label='sticky table'>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>S.no</StyledTableCell>
                                        {isMobile ? (
                                            <StyledTableCell>Project Title - Category - (Department)</StyledTableCell>
                                        ) : (
                                            <>
                                                <StyledTableCell>Project Code</StyledTableCell>
                                                <StyledTableCell>Project Title</StyledTableCell>
                                                <StyledTableCell>Category</StyledTableCell>
                                                <StyledTableCell>Department</StyledTableCell>
                                            </>
                                        )}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredProjects.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, key) => {
                                        return (
                                            <StyledTableRow hover role='checkbox' tabIndex={-1} key={key}>
                                                <StyledTableCell>{page * rowsPerPage + key + 1}</StyledTableCell>
                                                {isMobile ? (
                                                    <StyledTableCell>
                                                        <Link style={{ textDecoration: "none" }} to={`/project/${row.id}`}>
                                                            {row.title} - {row.category} - ({row.department})
                                                        </Link>
                                                    </StyledTableCell>
                                                ) : (
                                                    <>
                                                        <StyledTableCell>
                                                            <Link style={{ textDecoration: "none" }} to={`/project/${row.id}`}>
                                                                {row.project_code}
                                                            </Link>
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <Link style={{ textDecoration: "none" }} to={`/project/${row.id}`}>
                                                                {row.title}
                                                            </Link>
                                                        </StyledTableCell>
                                                        <StyledTableCell>{row.category}</StyledTableCell>
                                                        <StyledTableCell>{row.department}</StyledTableCell>
                                                    </>
                                                )}
                                            </StyledTableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component='div'
                            count={filteredProjects.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                )}

                {/* <div>
                    <table ref={printRef}>
                        <thead>
                            <tr>
                                <th>S.no</th>
                                <th>Project Code</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredProjects.map((row, key) => (
                                <tr key={key}>
                                    <td>{key + 1}</td>
                                    <td>{row.project_code}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div> */}
            </Container>
        </>
    );

    useEffect(() => {
        setIsLoading(true);
        setProgressMessage("Getting projects list");
        axiosInstance.get("/api/project").then((res) => {
            setProjects(res.data.data);
            setFilteredProjects(res.data.data);
            setIsLoading(false);
        });

        getDepartments()
            .then((res) => {
                setDepartments(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);
    return (
        <div>
            <Header />
            <Toolbar
                sx={{
                    // position: "sticky",
                    border: "1px solid #cacaca",
                    // top: { xs: "54px", sm: "64px" },
                    backgroundColor: "#fff",
                    justifyContent: "center",
                    pt: 2,
                    pb: { xs: 2, sm: 0 },
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    // zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
            >
                <Stack direction={isMobile ? "column" : "row"} spacing={isMobile ? 1 : 0} sx={{ width: "100%", borderRadius: 0, overflow: "hidden" }}>
                    <Stack direction='row' spacing={isMobile ? 1 : 0} sx={{ width: "100%", borderRadius: 0, overflow: "hidden" }}>
                        <FormControl sx={{ width: { xs: "50%", sm: 300 } }}>
                            <Select
                                name='department'
                                value={selectedDepartment}
                                // label='Department'
                                onChange={handleDeptChange}
                                input={<OutlinedInput sx={{ borderRadius: 0, borderRight: "none" }} size='small' />}
                                startAdornment={
                                    <InputAdornment position='start'>
                                        <BusinessIcon color='secondary' />
                                    </InputAdornment>
                                }
                            >
                                <MenuItem value='All'>All</MenuItem>
                                {departments.map((dept, key) => (
                                    <MenuItem key={key} value={dept.code}>
                                        {dept.code}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ width: { xs: "100%", sm: 500 } }}>
                            <Select
                                placeholder='select category'
                                name='category'
                                // label='Category'
                                value={selectedCategory}
                                onChange={handleCategoryChange}
                                input={<OutlinedInput sx={{ borderRadius: 0 }} size='small' />}
                                startAdornment={
                                    <InputAdornment position='start'>
                                        <CategoryIcon color='warning' />
                                    </InputAdornment>
                                }
                            >
                                <MenuItem value='All'>All</MenuItem>
                                {categories.map((category, key) => (
                                    <MenuItem key={key} value={category.name}>
                                        {category.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>
                    <OutlinedInput
                        size='small'
                        sx={{ mb: 2, borderRadius: 0 }}
                        placeholder='Search for projects'
                        name='search'
                        value={search}
                        onChange={handleSearch}
                        fullWidth
                        startAdornment={
                            <InputAdornment position='start'>
                                <SearchIcon color='primary' />
                            </InputAdornment>
                        }
                    />
                </Stack>
            </Toolbar>
            {/* <div>
                <Button variant='contained' color='primary' onClick={handleDownloadPdf} style={{ marginTop: "20px" }}>
                    Export as PDF
                </Button>
            </div> */}
            <Container maxWidth='xl' sx={{ p: 2, pt: 3 }}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                        height: "auto",
                        overflow: "hidden",
                        gap: 2,
                    }}
                >
                    {/* <Box
                        sx={{
                            flex: { xs: "1", md: "0 0 280px" },
                        }}
                    >
                        {renderFilters()}
                    </Box> */}
                    <Box
                        sx={{
                            flex: "1",
                        }}
                    >
                        {/* <OutlinedInput
                            size='small'
                            sx={{ mb: 2 }}
                            placeholder='Search for projects'
                            name='search'
                            value={search}
                            onChange={handleSearch}
                            fullWidth
                            startAdornment={
                                <InputAdornment position='start'>
                                    <SearchIcon color='primary' />
                                </InputAdornment>
                            }
                        /> */}
                        {isLoading && <Loader message={progressMessage} />}
                        {!isLoading && renderProjectCards()}
                    </Box>
                </Box>
            </Container>
            <Footer />
        </div>
    );
}

export default Project;
