// components/Navbar.js
import React, { useEffect, useState } from "react";
import { AppBar, Toolbar, Typography, Button, Box, Tooltip, useMediaQuery, IconButton, Menu, MenuItem } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logopng.png";
import { useTheme } from "@emotion/react";
import MenuIcon from "@mui/icons-material/Menu";

import AppsIcon from "@mui/icons-material/Apps";
import CollectionsIcon from "@mui/icons-material/Collections";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import DiscountBanner from "../common/discountBanner";

const Header = () => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect mobile view

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (path) => {
        navigate(path);
        setAnchorEl(null);
    };
    const home = () => {
        window.location.href = "/";
    };

    return (
        <>
            <AppBar
                color=''
                position='sticky'
                sx={{
                    // height: '50px !important',
                    backgroundColor: "white",
                    // backdropFilter: "blur(500px)",
                    // borderBottom: "1px solid #cacaca",
                    padding: 0,
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
            >
                <DiscountBanner />
                <Toolbar sx={{ pl: 2, pr: 2, height: 40 }}>
                    {isMobile ? (
                        <Box className='header'>
                            <Tooltip title='Tech Logics' placement='right'>
                                {/* <Link onClick={() => (window.location.href = "/")}> */}
                                <Box
                                    onClick={home}
                                    component='img'
                                    sx={{
                                        height: 40,
                                    }}
                                    src={logo}
                                />
                                {/* </Link> */}
                            </Tooltip>
                            {/* <Typography variant="h6">TECH LOGICS</Typography> */}
                            <IconButton edge='start' color='inherit' aria-label='menu' onClick={handleMenuOpen}>
                                <MenuIcon />
                            </IconButton>
                            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose} sx={{ width: "300px" }}>
                                <MenuItem color='primary' onClick={() => handleMenuClose("/projects")}>
                                    <AppsIcon color='primary' sx={{ mr: 1 }} />
                                    <Typography color='primary'>Projects</Typography>
                                </MenuItem>
                                {/* <MenuItem onClick={() => handleMenuClose("/")}>
                                    <CollectionsIcon color='secondary' sx={{ mr: 1 }} /> <Typography color='secondary'>Gallery</Typography>
                                </MenuItem> */}
                                <MenuItem onClick={() => handleMenuClose("/about-us")}>
                                    <InfoOutlinedIcon color='success' sx={{ mr: 1 }} /> <Typography color='success'>About Us</Typography>
                                </MenuItem>
                                <MenuItem onClick={() => handleMenuClose("/contact-us")}>
                                    <ContactPageOutlinedIcon color='warning' sx={{ mr: 1 }} /> <Typography color='warning'>Contact Us</Typography>
                                </MenuItem>
                            </Menu>
                        </Box>
                    ) : (
                        <>
                            <Tooltip title='Tech Logics' placement='right'>
                                <Link to={"/"}>
                                    <Box
                                        component='img'
                                        sx={{
                                            height: 50,
                                        }}
                                        src={logo}
                                    />
                                </Link>
                            </Tooltip>
                            <Typography component='div' sx={{ flexGrow: 1 }}></Typography>
                            <Button size='large' sx={{ textTransform: "none" }} onClick={() => navigate("/projects")} color='primary'>
                                Projects
                            </Button>
                            {/* <Button color='primary'>Gallery</Button> */}
                            <Button size='large' sx={{ textTransform: "none" }} onClick={() => navigate("/about-us")} color='primary'>
                                About us
                            </Button>
                            <Button size='large' color='primary' sx={{ textTransform: "none" }} onClick={() => navigate("/contact-us")}>
                                Contact us
                            </Button>
                        </>
                    )}
                </Toolbar>
            </AppBar>
        </>
    );
};

export default Header;
